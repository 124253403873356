import React from 'react'
import Layout from '../layouts'
import InsertText from '../utils/insert-text'
import { checkTheme } from '../utils/informations'

const ComplaimentsProcedure = () => {
  return (
    <Layout pageTitle={`Complaints Procedure - ${checkTheme().pageTitle}`}>
     
       <InsertText text={'complaints'}/>
    </Layout>
  )
}

export default ComplaimentsProcedure